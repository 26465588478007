import { object, string, number, array } from 'yup'
import { dateSchema } from './common/evaluation.js'
import { goalStatuses } from '../../consts/goal.js'
import { userSchema } from './common/user-object.js'

// Single goal schema
const goalSchema = object()
  .shape({
    // Required always
    title: string()
      .strict()
      .required('Title is required.')
      .max(255, 'Title must not exceed 255 characters.'),
    dueDate: dateSchema.required('Due date is required.'),
    description: string().strict().required('Description is required.'),
    priority: number()
      .min(1, 'Priority must be between 1 and 5.')
      .max(5, 'Priority must be between 1 and 5.')
      .required('Priority is required.'),
    // Required after a goal created
    status: string().oneOf(Object.values(goalStatuses)).required(),
    uid: string().strict().required(),
    createdDate: dateSchema.required(),
    managerSuggestions: string().notRequired(), // Might be empty string
    reportedProgress: number().required(),
    managerAssessedProgress: number().required(),
    // User information
    companyId: string().strict().required(),
    userId: string().strict().required()
  })
  .noUnknown(true)

// Exporting schemas
export const createGoal = goalSchema

export const updateGoal = goalSchema

export const deleteGoal = goalSchema

export const getUserGoals = array()
  .of(goalSchema)
  .required('Goals are required.')

export const batchGetGoals = object().shape({
  goals: array().of(goalSchema).required(),
  employees: array().of(userSchema).required()
})
