import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { useDialog } from '/src/hooks/use-dialog'
import usersRepo from '/src/data/repos/users-repo'
import consoleLog from '/src/utils/console-log'
import { tutorials } from '/src/constants/tutorials'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import {
  Box,
  Typography,
  List,
  ListItem,
  Stack,
  CircularProgress,
  SvgIcon
} from '@mui/material'
import { useState, useEffect } from 'react'
import { TUTORIAL_IDS } from '/src/constants/tutorials'
import Center from '/src/components/center'
import TextButton from '/src/components/buttons/text-button'
import PrimaryButton from '/src/components/buttons/primary-button'
import { useUser } from '/src/hooks/use-user'

export const useTutorial = ({
  tutorialId = '',
  pages = [],
  page = 0,
  handleComplete
}) => {
  const [tutorial, setTutorial] = useState({
    page
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loadedContent, setLoadedContent] = useState({})

  // Sequential preloading effect
  useEffect(() => {
    const elements = new Map()
    let isActive = true

    const preloadContent = async () => {
      // Function to preload a single video
      const preloadVideo = (url) =>
        new Promise((resolve) => {
          const video = document.createElement('video')
          elements.set(url, video)
          video.preload = 'auto'
          video.src = url

          const handleLoad = () => {
            if (isActive) {
              setLoadedContent((prev) => ({ ...prev, [url]: true }))
            }
            resolve()
          }

          video.onloadeddata = handleLoad
          video.onerror = handleLoad // Still resolve but log error
        })

      // Function to preload a single image
      const preloadImage = (url) =>
        new Promise((resolve) => {
          const img = new Image()
          elements.set(url, img)
          img.src = url

          const handleLoad = () => {
            if (isActive) {
              setLoadedContent((prev) => ({ ...prev, [url]: true }))
            }
            resolve()
          }

          img.onload = handleLoad
          img.onerror = handleLoad // Still resolve but log error
        })

      // Load content sequentially
      for (const page of pages) {
        if (!isActive) break

        if (page.videoUrl) {
          await preloadVideo(page.videoUrl)
        } else if (page.imageSrc) {
          await preloadImage(page.imageSrc)
        }
      }
    }

    preloadContent()

    return () => {
      isActive = false
      // Cleanup elements
      elements.forEach((element) => {
        if (element instanceof HTMLVideoElement) {
          element.src = ''
          element.load()
        }
      })
      elements.clear()
    }
  }, [pages])

  const handleNext = async () => {
    if (tutorial.page === pages.length - 1) {
      setIsSubmitting(true)
      await handleComplete(tutorialId)
      setIsSubmitting(false)
      return
    }

    return setTutorial((prevState) => ({
      ...prevState,
      page: prevState.page + 1
    }))
  }

  const handlePrev = () => {
    if (tutorial.page === 0) return

    setTutorial((prevState) => ({
      ...prevState,
      page: prevState.page - 1
    }))
  }

  return {
    tutorial,
    handleNext,
    handlePrev,
    isSubmitting,
    loadedContent
  }
}

export const TutorialContentDisplay = ({
  pages,
  tutorialId,
  handleComplete,
  isRequired
}) => {
  const { handleNext, handlePrev, tutorial, isSubmitting, loadedContent } =
    useTutorial({
      tutorialId,
      page: 0,
      pages: pages,
      handleComplete
    })
  const tutorialDetails = pages[tutorial.page]
  const index = tutorial.page
  const length = pages.length

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 1,
          width: '100%'
        }}
      >
        <TextButton
          size="large"
          endIcon={
            <SvgIcon>
              <ExitToAppIcon />
            </SvgIcon>
          }
          style={{ visibility: isRequired ? 'hidden' : 'visible' }}
          onClick={() => handleComplete()}
        >
          Exit
        </TextButton>
      </Box>
      <Stack
        sx={{
          flexDirection: {
            md: 'column',
            lg: 'row'
          },
          flexGrow: 1,
          overflow: 'auto'
        }}
      >
        {tutorialDetails?.videoEmbed ? (
          <Box sx={{ px: 5, width: '100%', height: '100%', margin: '0 auto' }}>
            <iframe
              title="Tutorial Video"
              src={tutorialDetails.videoEmbed}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                borderRadius: '8px'
              }}
            ></iframe>
          </Box>
        ) : (
          <>
            {/* Texts */}
            <Stack
              sx={{
                p: 10,
                height: '100%',
                width: '100%',
                flexGrow: 1,
                alignItems: 'start',
                justifyContent: 'center'
              }}
            >
              <Typography variant="h2">
                {tutorialDetails?.title || ''}
              </Typography>
              <Typography
                sx={{
                  marginTop: 3
                }}
                variant="body1"
              >
                {tutorialDetails?.description || ''}
              </Typography>
              <List>
                {tutorialDetails?.bulletPoints.map((bulletPoint, i) => (
                  <ListItem
                    key={i}
                    sx={{
                      padding: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        marginTop: 3
                      }}
                      variant="h6"
                    >
                      {bulletPoint?.title || ''}
                    </Typography>
                    <Typography
                      sx={{
                        marginTop: 1
                      }}
                    >
                      {bulletPoint?.description || ''}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Stack>
            {/* Visual */}
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%'
              }}
            >
              <Stack
                sx={{
                  boxShadow: 24,
                  borderRadius: 10,
                  width: '90%',
                  minHeight: 300
                }}
              >
                <Center
                  sx={{
                    position: 'relative'
                  }}
                >
                  {tutorialDetails?.videoUrl ? (
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%'
                      }}
                    >
                      {(!loadedContent[tutorialDetails.videoUrl] ||
                        !tutorialDetails.videoUrl) && (
                        <Center
                          sx={{
                            position: 'absolute',
                            zIndex: 1000,
                            height: '100%',
                            width: '100%',
                            backdropFilter: 'blur(5px)'
                          }}
                        >
                          <CircularProgress />
                        </Center>
                      )}
                      <video
                        width="100%"
                        height="100%"
                        autoPlay
                        loop
                        volume={1}
                        muted={true}
                        src={tutorialDetails.videoUrl}
                        preload="auto"
                        style={{
                          opacity: loadedContent[tutorialDetails.videoUrl]
                            ? 1
                            : 0,
                          transition: 'opacity 0.3s ease-in-out'
                        }}
                      >
                        <source
                          src={tutorialDetails.videoUrl}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </Box>
                  ) : (
                    <img
                      src={tutorialDetails?.imageSrc || ''}
                      alt={tutorialDetails?.imageAlt || ''}
                      style={{
                        width: '100%',
                        height: '100%',
                        opacity: loadedContent[tutorialDetails?.imageSrc]
                          ? 1
                          : 0,
                        transition: 'opacity 0.3s ease-in-out'
                      }}
                    />
                  )}
                </Center>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
      {/* Buttons: Previous and Next */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        m={3}
      >
        <PrimaryButton
          color="primary"
          onClick={handlePrev}
          style={{ visibility: index === 0 ? 'hidden' : 'visible' }}
        >
          Previous
        </PrimaryButton>

        <Typography sx={{ fontSize: 16 }}>{`${
          index + 1
        } of ${length}`}</Typography>

        <PrimaryButton
          color="primary"
          onClick={handleNext}
          disabled={isSubmitting}
        >
          {index + 1 === length
            ? tutorialId === TUTORIAL_IDS.ADMIN_INTRO_TUTORIAL && isRequired
              ? 'Start Exploring'
              : 'Complete'
            : 'Next'}
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}

const TutorialModal = ({
  tutorialId,
  user = null,
  isRequired = false,
  onClose = () => null
}) => {
  const tutorialPages = tutorials[tutorialId]['contents']
  const { open, handleClose, handleOpen } = useDialog()
  const { updateUser } = useUser()

  const handleComplete = React.useCallback(
    async (tutorialId) => {
      if (!isRequired) {
        onClose()
        return handleClose()
      }
      // Update tutorial status in the backend for the given tutorial name
      try {
        const result = await usersRepo.updateTutorials({
          name: tutorialId,
          completed: true
        })
        updateUser({
          ...user,
          tutorials: result
        })

        consoleLog('result in updateTutorials', result)
        return handleClose()
      } catch (error) {
        console.warn(error)
      }
    },
    [handleClose, updateUser, user, onClose, isRequired]
  )

  React.useEffect(() => {
    handleOpen(tutorialId)
    return () => {}
  }, [tutorialId, handleOpen])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            // Center modal in the middle of the screen
            'position': 'absolute',
            'top': '50%',
            'left': '50%',
            'transform': 'translate(-50%, -50%)',
            // Styling
            'bgcolor': 'background.paper',
            '&:focus': {
              outline: 'none'
            },
            // Spacing
            'width': '95%',
            'height': '95%',
            'borderRadius': 2,
            'maxWidth': 2000,
            'maxHeight': 1200
          }}
        >
          {
            <TutorialContentDisplay
              {...{
                tutorialId,
                pages: tutorialPages,
                handleComplete,
                isRequired
              }}
            />
          }
        </Box>
      </Fade>
    </Modal>
  )
}

export default TutorialModal
