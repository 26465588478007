import { ROLES } from '../../constants/roles.js'
import { dateSchema } from './evaluation.js'
import { array, number, object, string, boolean } from 'yup'

export const userSchema = object()
  .shape({
    company: string().required('Company field is required.'),
    manager: string().notRequired(), // typically required, with exceptions, e.g backend may return null/undefined for C level
    email: string().when('status', {
      is: (_) => 'DELETED',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.email().required('Email field is required.')
    }),
    fullName: string().required('Full name field is required.'),
    uid: string().required('UID field is required.'),
    userRoles: array()
      .of(
        string()
          .oneOf([ROLES.ADMIN, ROLES.MANAGER, ROLES.EMPLOYEE])
          .required('User role must be a valid user role.')
      )
      .when('status', {
        is: (_) => 'DELETED',
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required()
      }),
    firstName: string().required('First name field is required.'),
    lastName: string().required('Last name field is required.'),
    // optional fields
    tutorials: array()
      .of(object().shape({ name: string(), completed: boolean() }).noUnknown())
      .optional(),
    team: string().optional(),
    division: string().optional(),
    status: string().optional(),
    location: string().optional(),
    gender: string().optional(),
    joinDate: dateSchema.optional(),
    jobLevel: number().optional(),
    seniority: string().optional(),
    race: string().optional(),
    jobTitle: string().optional(),
    cohort: string().optional(),
    salary: number().optional(),
    upgradeRequested: string().optional(),
    upgradeViewed: string().optional(),
    permissions: object().optional()
  })
  .noUnknown() // to prevent extra fields from being added to the object and activating striptUnknown

export const userDataForResultsSchema = object().shape({
  // User Data
  userId: string().required('User ID field is required.'),
  fullName: string().required('Full name field is required.'),
  manager: object()
    .shape({
      fullName: string().required('Full name field is required.'),
      uid: string().required('UID field is required.')
    })
    .notRequired()
    .default(undefined),
  team: string().optional(),
  division: string().optional(),
  status: string().optional(),
  gender: string().optional(),
  joinDate: dateSchema.optional(),
  jobLevel: number().optional(),
  seniority: string().optional(),
  race: string().optional(),
  jobTitle: string().optional(),
  salary: number().optional(),
  location: string().optional()
})
