import { ROLES } from '/functions/shared/constants/roles'
import React from 'react'

export enum TUTORIAL_IDS {
  REVIEW_PROCESS = 'reviewProcess',
  ADMIN_INTRO_TUTORIAL = 'adminIntroTutorial',
  MANAGER_ANALYTICS = 'managerAnalytics',
  ADMIN_UPLOAD_EMPLOYEES = 'adminUploadEmployees',
  ADMIN_WELCOME_VIDEO = 'adminWelcomeVideo',
  ADMIN_REVIEW_CYCLES_VIDEO = 'adminReviewCyclesVideo',
  ADMIN_BEHAVIORS = 'adminBehaviors'
}

export const tutorials = {
  [TUTORIAL_IDS.REVIEW_PROCESS]: {
    title: 'Learn About Incompass Reviews',
    role: ROLES.EMPLOYEE,
    contents: [
      // My Reviews
      {
        title: 'My Reviews',
        description:
          'My Reviews is your one-stop-shop for all your reviews. Here, you can submit your active reviews and view completed ones.',
        bulletPoints: [
          {
            title: 'Start an Ongoing Review',
            description:
              'The active review cycles will be listed here, and you can start your reviews by clicking the “Start” button.'
          },
          {
            title: 'Track Review Progress',
            description:
              'After submitting reviews, you can use this page to stay informed with real-time updates on the status of ongoing review cycles.'
          },
          {
            title: 'View Your Results',
            description:
              'Once all reviews are completed, you can access your results here to uncover strengths and areas to develop.'
          }
        ],
        videoUrl: '/assets/videos/employees/my-reviews-to-selection.mp4'
      },
      // Selection
      {
        title: 'Select Colleagues',
        description:
          'Once you start a review process, the first step will be to select colleagues to review. You will review these colleagues in the following steps.',
        bulletPoints: [
          {
            title: 'Suggested Colleagues',
            description:
              'The selection panel will include suggested colleagues based on your team. You can add or remove colleagues, except for the mandatory ones.'
          },
          {
            title: 'Search and Add Colleagues',
            description:
              'You can search for colleagues by name, team, or division, and select them to give feedback.'
          }
        ],
        videoUrl: '/assets/videos/employees/selection-to-questions.mp4'
      },
      // Question card overview
      {
        title: 'Review Your Colleagues',
        description:
          'After selecting your colleagues, you will go through a series of quick review questions. The questions will ask you to provide feedback to your colleagues on company behaviors.',
        bulletPoints: [
          {
            title: 'Using the Sliders',
            description:
              'Use the sliders to indicate how well you think your colleagues display each behavior.'
          },
          {
            title: 'Give Quality, Meaningful Feedback',
            description:
              'You can use the feedback panel to give anonymous written feedback to your colleagues.'
          },
          {
            title: 'Improve and Refine your Feedback with AI',
            description:
              'Get suggestions from our AI on how to improve your feedback to ensure it is balanced and meaningful.'
          }
        ],
        videoUrl: '/assets/videos/employees/questions-to-submit.mp4'
      },
      // Submit page
      {
        title: 'Submit Your Reviews',
        description:
          'Once you have reviewed all your colleagues, you can view a summary of your feedback and submit your reviews.',
        bulletPoints: [
          {
            title: 'View Summary',
            description:
              'You can view a summary of your feedback before submitting your reviews, and go back to make changes if needed.'
          },
          {
            title: 'Submit',
            description:
              'If you are satisfied with your feedback, you can click "Submit Your Feedback" - and you will have completed your reviews!'
          },
          {
            title: 'View Your Inputs',
            description:
              'After submitting your reviews, you can view the feedback you\'ve given to your colleagues by clicking on the "Your Inputs" button.'
          }
        ],
        videoUrl: '/assets/videos/employees/submit-to-my-reviews.mp4'
      }
    ]
  },
  [TUTORIAL_IDS.MANAGER_ANALYTICS]: {
    title: 'How to Use Incompass as a Manager',
    role: ROLES.MANAGER,
    contents: [
      {
        title: 'Incompass for Managers',
        description:
          'Incompass is an innovative tool for understanding and developing your team, in a fast and more objective way. This quick tutorial will guide you through the main features of Incompass.',
        bulletPoints: [
          {
            title: 'Assess and Calibrate Performance Objectively',
            description:
              'Incompass provides an objective and quantifiable performance overview of your team through incentive-compatible peer assessments, empowered by advanced statistics.'
          },
          {
            title: 'Track and Manage Review Cycles with Ease',
            description:
              'You can use the "Review Cycles" panel to stay informed with real-time updates on ongoing reviews and track the progress of your team.'
          },
          {
            title: 'Analyze Team Insights',
            description:
              'The "Analytics" panel let’s you analyze the results of your direct reports and view reports on your team’s performance.'
          }
        ],
        videoUrl: '/assets/videos/manager/manager-dashboard.mp4'
      },
      {
        title: 'Understand Your Team',
        description:
          'With an accurate and quantifiable performance calibration, Incompass let’s you discover comprehensive insights about your team and unlock precise learning & development opportunities for everyone.',
        bulletPoints: [
          {
            title: 'Innovative Team Insights',
            description:
              'Incompass let’s you get an accurate bird’s eye view of your team’s overall performance, as well as how each individual performs.'
          },
          {
            title: 'Easier, Data-Driven Learning & Development Conversations',
            description:
              'The data analyses at both individual and organizational levels provides you with the insights you need to have data-driven development conversations with your direct reports.'
          }
        ],
        videoUrl: '/assets/videos/manager/manager-results.mp4'
      }
    ]
  },
  [TUTORIAL_IDS.ADMIN_INTRO_TUTORIAL]: {
    title: 'Overview of Incompass for Admins',
    role: ROLES.ADMIN,
    contents: [
      {
        title: 'Welcome.',
        description:
          'Incompass is an innovative tool for understanding and developing your workforce, in a fast, objective, and scalable way. This quick tutorial will guide you through the main features of Incompass.',
        bulletPoints: [
          {
            title: 'Assess and Calibrate Performance Objectively',
            description:
              'Incompass provides an objective and quantifiable performance overview of your entire organization through incentive-compatible peer assessments, empowered by advanced statistics.'
          },
          {
            title: 'Track and Manage Review Cycles with Ease',
            description:
              'Incompass’s efficient interface helps you stay informed with real-time updates on ongoing reviews, and allows you to make customizations to fit your organization’s needs.'
          }
        ],
        imageSrc: '/assets/images/admin-review-cycles.png',
        imageAlt: 'Admin Dashboard'
      },
      {
        title: 'Understand Your Workforce',
        description:
          'With an accurate and quantifiable performance calibration, Incompass let’s you discover comprehensive insights about your entire workforce, make people decisions, and unlock precise learning & development opportunities for everyone.',
        bulletPoints: [
          {
            title: 'Innovative Workforce Insights',
            description:
              'Incompass let’s you get an accurate bird’s eye view of your organization’s overall performance, as well as how each individual performs.'
          },
          {
            title: 'Easier People Decisions',
            description:
              'The data analyses at both individual and organizational levels provides you with the insights you need to make informed people decisions.'
          }
        ],
        imageSrc: '/assets/images/admin-analytics.png',
        imageAlt: 'Review Results'
      },
      {
        title: 'Fast and Accurate Reviews',
        description:
          'Incompass’s unique approach to peer reviews incentives users to provide as accurate inputs as possible through a simple, quick, and customizable interface.',
        bulletPoints: [
          {
            title: 'Effective Employee Reviews',
            description:
              'Designed to make the review process as easy as possible for everyone, Incompass minimizes the time it takes to collect peer assessments, while ensuring the accuracy of the results.'
          },
          {
            title: 'Customizable Reviews and Results',
            description:
              'Incompass’s simple and adaptable interfaces let’s you customize the review process to fit your organization’s needs and Learning & Development goals.'
          }
        ],
        imageSrc: '/assets/images/review-questions.png',
        imageAlt: 'Review Results'
      }
    ]
  },
  // [TUTORIAL_IDS.ADMIN_CREATE_REVIEW_CYCLE]: {
  //   title: 'How to Create a Review Cycle',
  //   role: ROLES.ADMIN,
  //   contents: [
  //     {
  //       title: 'Creating a Review Cycle',
  //       description:
  //         'After uploading the employees, you can create your first review cycle by clicking the "Review Cycles" section on the left sidebar.',
  //       bulletPoints: [
  //         {
  //           title: 'Go to Review Cycles',
  //           description:
  //             'Go to the "Review Cycles" section to create a new review cycle.'
  //         },
  //         {
  //           title: 'Create a Cycle',
  //           description:
  //             'On the review cycles page, click the "Create a Cycle" button to create a new cycle.'
  //         },
  //         {
  //           title: 'Configure the Cycle',
  //           description:
  //             'On the new cycle page, you can configure the cycle settings, including participants and questions for the review.'
  //         },
  //         {
  //           title: 'Accessing This Tutorial',
  //           description:
  //             'Access this tutorial anytime by clicking the question mark icon in the top right corner and selecting "Getting Started with Incompass for Admins" from the tutorials list.'
  //         }
  //       ],
  //       videoUrl:
  //         '/assets/videos/admin-management/where-to-create-a-review-cycle.mp4'
  //     }
  //   ]
  // },
  [TUTORIAL_IDS.ADMIN_UPLOAD_EMPLOYEES]: {
    title: 'How to Manage Employee Data',
    role: ROLES.ADMIN,
    contents: [
      {
        title: 'Upload Employee Data',
        description:
          'Incompass lets you easily upload your employee data, automatically creating authorized users in Incompass and giving employees access through their emails.',
        bulletPoints: [
          {
            title: 'Start the Process',
            description:
              'You can start the process by clicking the "Upload Employee Data" button.'
          },
          {
            title: 'Select File',
            description:
              'Here you can select or drag & drop the file you want to upload, which must be a valid CSV file.'
          },
          {
            title: 'Preview',
            description:
              'Once you have selected the file, you can click the "Preview" button to see the employees that will be created or updated.'
          },
          {
            title: 'Upload',
            description:
              'Once you have reviewed the employees, you can click the "Upload" button to import your employees.'
          }
        ],
        videoUrl: '/assets/videos/admin-management/upload-employees.mp4'
      },
      {
        title: 'Editing Employee Data',
        description:
          'You can add or edit employees individually and give them custom data access permissions.',
        bulletPoints: [
          {
            title: 'Adding an Employees',
            description:
              'You can add a new employee by clicking the "Add an Employee" button.'
          },
          {
            title: 'Editing Employees',
            description:
              'You can edit employee details or remove employees using the edit and delete icons in each employee row.'
          },
          {
            title: 'Giving Data Access',
            description:
              'You can assign employees as admins for full data access, or customize their permissions for specific data access.'
          }
        ],
        videoUrl:
          '/assets/videos/admin-management/adding-and-editing-employees-and-assigning-admin.mp4'
      }
    ]
  },
  [TUTORIAL_IDS.ADMIN_WELCOME_VIDEO]: {
    title: 'Video: Welcome to Incompass',
    role: ROLES.ADMIN,
    contents: [
      {
        videoEmbed:
          'https://www.loom.com/embed/0ffaa4dcd2d84df2bb87a1c1261c6a6a?sid=57d1b751-0119-4847-b359-91a942c6b8ab&hide_owner=true'
      }
    ]
  },
  [TUTORIAL_IDS.ADMIN_REVIEW_CYCLES_VIDEO]: {
    title: 'Video: How to Manage Review Cycles',
    role: ROLES.ADMIN,
    contents: [
      {
        videoEmbed:
          'https://www.loom.com/embed/787d619390964e7e980e45a26fba2326?sid=9a71a3e1-265c-4f39-aeb8-c10d848d4c4c&hide_owner=true'
      }
    ]
  },
  [TUTORIAL_IDS.ADMIN_BEHAVIORS]: {
    title: 'Learn More About Behaviors',
    role: ROLES.ADMIN,
    contents: [
      {
        title: 'Behaviors',
        description:
          'Our clients rely on behaviors as the cornerstone of their 360 assessments because behaviors provide a tangible, actionable framework for performance evaluation and growth.',
        imageSrc: '/assets/images/behaviors-1.png',
        bulletPoints: [
          {
            description:
              'Unlike abstract qualities or esoteric values, behaviors are observable and measurable, offering a clear picture of how an individual interacts with colleagues, navigates challenges, and contributes to team dynamics.'
          },
          {
            description:
              'Focusing on behaviors allows organizations to align feedback with their values and goals while fostering a culture of accountability and development. By measuring behaviors, clients can identify strengths, uncover growth opportunities, and create targeted development plans that drive meaningful change at both the individual and organizational levels. Many of our clients use their values as their behaviors or as inspiration for what those company-wide behaviors might become.'
          }
        ]
      },
      {
        title: 'Adding Behaviors',
        imageSrc: '/assets/images/behaviors-2.png',
        description: React.createElement(
          'span',
          null,
          'We recommend adding the 3-6 key behaviors that are essential to your strategy and run your review cycle. Want to learn more about how to define concrete values? ',
          React.createElement(
            'a',
            {
              href: 'https://incompass-labs.com/resources/do-your-company-values-actually-set-you-apart',
              target: '_blank'
            },
            'Read more here.'
          )
        ),
        bulletPoints: [
          {
            description:
              'In the Behavior Box, you can add the name of the behavior, a link to external content on that behavior (optional) and a description of what that behavior looks like at your organization. For example, if a key behavior of yours is Accountability, you might write: '
          },
          {
            title: 'Name:',
            description: 'Accountability'
          },
          {
            title: 'Description: ',
            description:
              'Doing what you commit to do in the agreed upon timeline. Managing multiple activities and prioritizing them. Asking for help when you need it.'
          }
        ]
      }
    ]
  }
}
